@import "../../styles/mixins.scss";

.faq-list-header {
    font-size: 2.8rem;
    width: 75vw;
    margin: auto;
    padding: 8vh 0 0;
    font-weight: bold;
    color: #0B5E96;
    text-align: center;

    @include for-big-desktop-up {
        width: 65vw;
    }

    @include tabs-components {
        width: 85vw;
    }

    @include for-phone-only {
        font-size: 2rem;
        width: 90vw;
    }
}

.faq-list-wrapper {
    margin: 3vh auto 10vh;
}

.faq-list{
    list-style: none;
    margin: auto;
    padding: 0;
    background-color: #fff;
    max-width: 75vw;
    border-radius: 0;
    overflow: hidden;

    @include for-big-desktop-up{
        max-width: 65vw;
    }

    @include tabs-components {
        max-width: 85vw;
    }

    @include for-phone-only {
        max-width: 90vw;
    }
}

.faq-list-item-wrapper {
    &+&{
        border-top: 1px solid #EEEEEF;
      }
  }