@import "../../styles/mixins.scss";

.login-page-wrapper {
  padding-top: 60px;
  min-height: 100%;
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  z-index: 0;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/img/litecampus-bg.svg');

  @include for-collapsed-navbar {
    height: 89.5vh;
  }

  .login-container {
    display: flex;
    overflow: auto;
    padding: 4vh 0;

    @media (max-width: 600px) {
      padding: 6vh 0;
    }

    .login-row {
      margin: auto;
      min-width: 80%;
      align-items: center;

      @media (max-width: 600px) {
        min-width: 90%;
      }

      .login-top-logo {
        height: 8vw;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 7vh;
        background-image: url('../../assets/img/liteme-maps-white.svg');

        @media (max-width: 990px) {
          height: 11.5vw;
        }

        @media (max-width: 750px) {
          height: 21vw;
        }

        @media (max-width: 600px) {
          height: 28vw;
          margin-bottom: 5vh;
        }
      }

      .login-bottom-logo {
        height: 4.5vw;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 2vh;
        opacity: 0.8;
        background-image: url('../../assets/img/smartiks.svg');

        @media (max-width: 990px) {
          height: 6.5vw;
        }

        @media (max-width: 750px) {
          height: 8.5vw;
        }

        @media (max-width: 600px) {
          height: 11.5vw;
        }
      }

      .login-form-wrapper {
        margin: auto;
        display: grid;
        width: 60%;

        @media (max-width: 1230px) {
          width: 75%;
        }

        @media (max-width: 990px) {
          width: 65%;
        }

        @media (max-width: 750px) {
          width: 75%;
        }

        @media (max-width: 600px) {
          width: 95%;
        }

        .login-form-error-msg {
          position: relative;
          background-color: #f2dede;
          padding: 15px;
          margin-bottom: 25px;
          // padding: 1.5vh;
          // margin-bottom: 2.5vh;
          color: #a94442;

          .hide-error-msg-btn {
            color: #000;
            text-shadow: 0 1px 0 #fff;
            // text-shadow: 0 0.1vh 0 #fff;
            float: right;
            font-size: 21px;
            // font-size: 2.1vh;
            font-weight: 700;
            line-height: 1;
            opacity: 0.2;
            cursor: pointer;
          }
        }

        .login-form-row {
          margin-bottom: 25px;
          // margin-bottom: 2.5vh;

          input {
            height: 40px;
            // height: 4vh;
            border: none;
            border-radius: 0;
            font-size: 1.8vh;
            // font-size: 1.8vh;

            &:focus {
              box-shadow: 0px 0px 8px #4c1198;
              // box-shadow: 0vh 0vh 0.8vh #4c1198;
              border: none;
            }
          }

          .show-pswd-btn {
            background-color: #4c1198;
            border: none;
            border-radius: 0;
            color: #FFFFFF;
            min-width: 44px;
            // min-width: 4.4vh;
            // font-size: 1.9vh;

            svg {
              margin: auto;
            }
          }
        }

        .login-btn-row {
          text-align: center;
          margin: 2vh 0 4vh;

          button {
            height: 40px;
            // height: 4vh;
            color: #4c1198;
            min-width: 40%;
            border-radius: 25px;
            // border-radius: 2.5vh;
            background-color: #FFFFFF;
            font-weight: bold;
            border-width: 0;
            // font-size: 1.9vh;

            @media (max-width: 1230px) {
              min-width: 35%;
            }

            @media (max-width: 990px) {
              min-width: 25%;
            }

            @media (max-width: 750px) {
              min-width: 35%;
            }

            @media (max-width: 600px) {
              min-width: 40%;
            }

            &:hover, &:focus, &:active, &:focus-visible {
              color: #FFFFFF;
              background-color: #4c1198;
              outline: none;
            }
          }
        }

        .login-check {
          text-align: right;
          color: #FFFFFF;
          margin-bottom: 25px;
          // margin-bottom: 2.5vh;
          // font-size: 1.5vh;
          

          span {
            vertical-align: text-bottom;
            font-size: 1.2rem;
            // font-size: 1.9vh;
          }

          svg {
            margin-left: 10px;
            // margin-left: 1vh;
          }
        }

        .login-form-text-row {
          margin-bottom: 15px;
          // margin-bottom: 1.5vh;
          text-align: center;
          font-size: 1.2rem;
          height: 20px;
          // font-size: 1.9vh;
          // height: 2vh;
          color: #FFFFFF;
          cursor: pointer;

          &:hover {
            outline: none;
            font-size: 1.4rem;
            // font-size: 2.2vh;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.card {
  border-color: #073b50 !important;
  width: 50%;
  margin: 10% 0 0 20%;

  .card-header {
    color: white;
    background-color: #073b50 !important;
    border-color: #073b50 !important;
  }
  .card-body {
    color: white;
    width: 100%;
    
    label {
      color: #2f2f2f;
    }

  }

  .login-button {
    background-color: #073b50;
  }
}
