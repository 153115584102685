.object-detector-row {
  margin-top: 20px;
  .object-detector-container {
    min-height: 200px;
    background-image: url("../../../assets/img/loading.gif");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    img {
      width: 100%;
    }

    .video-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
    }
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
