.hex {
  width: 100%;
  height: 81%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: table;
  font-size: 1em;

  &.energy {
    background-image: url("../../assets/img/block-data-energy.svg");

    &.outlined {
      background-image: url("../../assets/img/energy_outlined.svg");
      height: calc((50vw - 120px)/4);
      .hex-value {
        color: #ffb744;
      }
    }
  }

  &.water {
    background-image: url("../../assets/img/block-data-water.svg");

    &.outlined {
      background-image: url("../../assets/img/water_outlined.svg");

      .hex-value {
        color: #007c9f;
      }
    }
  }

  &.mobsense {
    background-image: url("../../assets/img/block-data-water.svg");

    &.outlined {
      background-image: url("../../assets/img/mobsense_outlined.svg");

      .hex-value {
        color: #F35456;
      }
    }
  }

  .hex-value {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: 2.57em;
    font-size: calc((50vw - 120px)/16);
    .hex-unity {
      margin: -0.8vh 0vh 0vh 0vh;
      font-weight: bold;
      font-size: 0.5em
    }
  }

  // @media (min-width: 1200px){
  //   .hex-unity {
  //     font-size: 0.5em;
  //   }
  //   .hex-value {
  //     font-size: 2.5em;
  //   }
  // }

  // @media (min-width: 1500px){
  //   .hex-unity {
  //     font-size: 0.7em;
  //   }
  //   .hex-value {
  //     font-size: 3.5em;
  //   }
  // }

  // @media (min-width: 1900px) {
  //   .hex-unity {
  //     font-size: 0.7em;
  //   }
  //   .hex-value {
  //     font-size: 3em;
  //   }
  // }
  @media (min-width: 3000px) {
    .hex-unity {
      font-size: 0.7em;
    }
    .hex-value {
      font-size: 6em;
    }
  }
  
}
