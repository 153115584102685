.pagination {
  height: 8%;
  width: 100%;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  justify-content: space-around;
  align-items: center;
  color: 303030;
  background-color: #dbdbdb;

  .previous {
    border: 1px solid #fff;
    background-color: #fff;
    padding: 5px 5px;
    border-radius: 25px;

    &.disabled {
      display: none;
    }
  }

  .next {
    border: 1px solid #fff;
    background-color: #fff;
    padding: 5px 5px;
    border-radius: 25px;

    &.disabled {
      display: none;
    }
  }

  .page-item {
    margin: 0 5px;
    border: 1px solid #fff;
    background-color: #fff;
    padding: 5px 15px;
    border-radius: 25px;

    &.break {
      background-color: transparent;
      border: none;
    }

    &.active {
      font-weight: bold;
      color: #fff;
      background-color: #303030;
      border: 1px solid #303030;
      border-radius: 25px;
    }
  }
}