.alert-list {
  height: 100%;
  display: flex;
  flex-direction: column;

  .filters {
    padding: 20px 0px 10px 0px;
    margin: 0;
    border-bottom: 2px solid #303030;
    display: flex;
    justify-content: space-between;
    // position: sticky;
    // top: 0;

    .filters-row {
      display: flex;

      .filters-row:first-child {
        padding-left: 0;
      }
    }
  }

  .add-alert {
    background-color: #303030;
    border: none;
    height: 35px;
    border-radius: 0px;
    // font-size: 1em;
    // @media (min-width: 1200px){
    //   font-size: 0.5em;
    // }
    // @media (min-width: 1500px){
    //   font-size: 0.75em;
    // }
    // @media (min-width: 1900px) {
    //   font-size: 1em;
    // }
    // @media (min-width: 3000px) {
    //   font-size: 2.5em;
    //   border-radius: 0.2em;
    // }

    &:hover {
      background-color: #707070;
    }

    &:active {
      background-color: #303030 !important;
    }

    &:focus {
      background-color: #303030;
      outline: none;
      box-shadow: none !important;
    }
  }
}

.list-alerts {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .list-headers {
    margin: 0;
    padding: 5px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    // font-size: 1.5vh;
  
    .last-header {
      padding-left: 10px;
    }
  }

  .alerts {
    overflow: scroll;
  }
}

.list-alerts::-webkit-scrollbar {
  display: none;
}