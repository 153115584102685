#search_input {
  border-bottom: 0px solid !important;
  font-size: 14px;
  right: 2px;
  top: 10px;
  // font-size: 1.4vh;
  // right: 0.2vh;
  // top: 1vh;
  font-weight: bold;
  pointer-events: none;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.enum {
  width: 40% !important;

  &.left {
    margin-right: 20% !important;
  }

  &.right {
    margin-left: 20% !important;
  }
}

.searchBox#search_input::placeholder {
  color: #555;
}

.group {
  margin-bottom: 20px !important;

  // margin-bottom: 5vh !important;
  &.time {
    margin-bottom: 40px !important;
    // margin-bottom: 4vh !important;
  }

  &.select {
    margin-bottom: 10px !important;
    // margin-bottom: 1vh !important;
  }

  &.checkbox {
    // margin-top: 2vh;
    // margin-left: 1vh;
    width: 100% !important;
    padding-left: 5px !important;
    // padding-left: 0.5vh !important;
    // .actuators-checkbox {
    //   font-weight: bold;
    //   font-size: 0.5em;
    //   color: #555;
    //   margin-left: 3vh;
    //   margin-top: -4.8vh;
    //   margin-bottom: -3vh;
    // }
  }
}

.custom-control-label {
  top: 0px !important;
  left: 20px !important;

  // top: 0vh !important;
  // margin: 0.5vh !important;
  // font-size: 0vh;  
  &::before {
    top: 0.1rem !important;
    // top:  0.16vh !important;
    // width: 1.5vh;
    // height: 1.5vh;
  }

  &::after {
    top: 0.1rem !important;
    // top: 0.16 !important;
    // width: 1.5vh;
    // height: 1.5vh;
  }
}

.add-rule-checkbox {
  padding-left: 0;
  width: 100% !important;
}

.modal-header {
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  // padding: 0.48vh 0.8vh 0.48vh 0.8vh;
}

.modal-title {
  height: 10% !important;
  text-align: center;
}

.modal-content {
  border: none;
  box-shadow: #00000088 0px 10px 15px;
  // box-shadow: #00000088 0vh 1vh 1.5vh;
  border-radius: 0;
  // height: flex;
  // width: 50vh;
}

.modal-body {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: flex-start;
  max-height: 90rem !important;
  min-height: 8rem !important;
  padding-bottom: 0rem !important;
  // max-height: 144vh!important;
  // min-height: 12.8vh !important;
  // padding-bottom: 2vh !important;
  // height: 50%;
  // width: 40vh;
  .modal-form {
    max-height: 29rem !important;
    min-height: 2rem !important;
    // max-height: 46.64vh!important;
    // min-height: 3.2vh !important;
  }

  .rule-header {
    width: 95%;
    margin: auto;
    background-color: #56968f;
    margin-bottom: 5px;
    // margin-bottom: 0.5vh;
    align-items: center;
    height: 30px;
    // height: 3vh;
    color: #ffffff;

    &.off {
      background-color: #e6e6e6;
      color: #707070;
    }
  }

  .switcher {
    align-self: center;
  }
}

.modal-body::-webkit-scrollbar {
  display: none;
}

.modal-open .modal {
  overflow-y: hidden;
}

// .modal-dialog {
//   font-size: 1.5vh;
// }

.rules-button {
  justify-content: center;
  background-color: #00796b !important;
  margin-top: 4rem !important;
  margin-bottom: 1.5rem !important;
  width: 100% !important;
  // margin-top: 4vh !important;
  // margin-bottom: 2.4vh !important;
  // width: 30% !important;
  border: none;
  // font-size: 1.5vh;

  &:hover {
    background-color: #029685 !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.custom-control-input {
  z-index: 0;
  // left: 1vh;
}

// .search-wrapper {
//   font-size: 1.5vh;

//   .chip {
//     margin: 0.5vh;
//     font-size: 1.5vh;
//   }
// }