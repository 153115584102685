.label {
  margin-top: 10px;
  text-align: center;
  & .title {
    margin: 0;
    font-weight: bold;

    &.energy {
      color: #f8be22;
    }
  
    &.wifi {
        color: #7e0cf5;
    }
  
    &.water {
        color: #007d9f;
    }
  }

  .subtitle {
    color: #ababab;
    font-size: 1.5vh;
  }
  
  @media (min-width: 1200px){
    .title {
      font-size: 1.2em;  }
    .subtitle {
      font-size: 0.9em;
    }
  }
  @media (min-width: 1900px) {
    .title {
      font-size: 1.34em;  }
    .subtitle {
      font-size: 1em;
    }
  }
  @media (min-width: 3000px) {
    .title {
      font-size: 3em;  
    }
    .subtitle {
      font-size: 2.5em;
    }
  }
}


