.user-header {
  margin: 2.5px 0px;
  // margin: 0.25vh 0vh;
  color: black;
  font-size: 0.9em;
  // font-size: 1.4vh;
  min-height: 60px;
  border-width: 3px;
  padding: 5px;
  // height: 6vh;
  // border-width: 0.3vh;
  // padding: 0.5vh;
  background-color: #FFFFFF;
  color: #707070;
  display: flex;

  .button {
    align-self: center;
    cursor: pointer;

    &.access-management {
      flex: 1;
      padding: 0;
      text-align: center;
      height: 40px;
      line-height: 40px;
      // height: 4vh;
      // line-height: 4vh;
      color: #FFFFFF;
      background-color: #707070;
      font-weight: bold;
      font-size: 25px;
      // font-size: 2.5vh;
    }
  }

  .num-text {
    display: flex;
    flex: 4;

    .user-num {
      margin: auto;
      font-size: 1rem;
      // font-size: 1.6vh;
    }
  }

  .text {
    overflow-y: auto;
    height: 100%;
    flex: 7;

    .user-description {
      font-size: 1.2rem;
      // font-size: 1.9vh;
    }

    .user-identifier {
      font-size: 0.8rem;
      // font-size: 1.2vh;
    }
  }
}