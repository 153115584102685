.energy {
    .filters {
        padding: 18px 0px 9px 0px;
        margin: 0;

        .filter:first-child {
            padding-left: 0;
        }

        .order-filter {
            width: 15%;
        }

        .description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5px 15px;
            border: 1px solid #707070;
            // padding-left: 1.5vh;
            // border: 0.2vh solid #707070;
            // font-size: 1.4vh;

            &.increasing-consumption {
                background-color: #f15b29;
            }

            &.decreasing-consumption {
                background-color: #0b5e96;
            }

            &.normal-consumption {
                background-color: #fdc200;
            }

            width: 100%;
            position: relative;
            top: -2px;
            // top: -0.2vh;
            color: white;

            // height: 7vh;
            .description-title {
                color: white;
                font-weight: bold;
            }
        }

        .list-search {
            width: 100%;
            height: 36px;
            font-size: 1rem;
            margin-top: 9px;
            // font-size: 1.6vh;
            // margin-top: 1vh;

            .list-search-label {
                height: 36px;
                width: 100%;
                display: grid;
                display: flex;
                align-items: center;

                .list-search-icon {
                    position: absolute;
                    margin: auto auto auto 0.7vw;
                    color: #707070;
                }

                .list-search-input {
                    padding-left: 2.3vw;
                    border-radius: 0;
                    border: 1px solid #707070;
                    width: 100%;
                    height: 100%;
                    // border: 0.1vh solid #707070;

                    &:focus {
                        border-radius: 0;
                        border: 2px solid #707070;
                        // border: 0.2vh solid #707070;
                        outline: none;
                    }
                }
            }
        }
    }
}

.table-detailed {
    flex-grow: 1;
    overflow: auto;
    height: 60vh;
    
    .table-detailed-row {
        display: flex;
        align-items: center;
        height: 100%;

        .list-item-consumption {
            flex: 1;
        }

        .list-item-name-wrapper {
            flex: 2;
        }

        @media (min-width: 3000px){
            .status-indicator-wrapper {
                font-size: 36px;
            }
        }
    }
}

.status-indicator-header-wrapper {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-bottom: -2px;

    &.above {
        background-color: #f15b29;
    }

    &.below {
        background-color: #0b5e96;
    }

    &.normal,
    &.invalid {
        background-color: #fdc200;
    }

    .status-indicator {
        height: 100%;
        font-size: 15px;
        // font-size: 1.5vh;
        color: white;
        margin: auto;
        display: flex;

        &.status-indicator-filter {
            color: #707070;
        }
    }
}

.status-indicator-wrapper {
    margin-right: 0.5vh;
    padding: 1vh 1vh 1vh 1vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &.status-indicator-wrapper-filter {
        margin: auto;
        padding: 0;
        height: fit-content;
    }

    &.ed-above {
        background-color: #f15b29;
    }

    &.ed-below {
        background-color: #0b5e96;
    }

    &.ed-invalid,
    &.ed-normal {
        background-color: #fdc200;
    }

    &.ed-offline,
    &.ed-undefined {
        background-color: #D2D4D2;
    }

    .status-indicator {
        // height: 70%;
        font-size: 1em;
        color: white;
        margin: auto;
        display: flex;
        align-items: center;

        &.status-indicator-filter {
            color: #707070;
        }
    }
}

.detailed-view-filters {
    background-color: #eeeeef;
    width: 100%;
    display: inline-flex;
    font-weight: bold;

    .detailed-filter-wrapper {
        display: flex;
        cursor: pointer;
        padding: 5px;
        border: 1px solid #707070;
        border-left-width: 0px;
        border-bottom-width: 1px;
        justify-content: center;
        text-align: center;
        align-items: center;
        color: #707070;

        // @media (min-width: 1200px) {
        //     font-size: 0.9em;
        //     padding: 0.5em;
        // }

        // @media (min-width: 1500px) {
        //     font-size: 0.9em;
        //     padding: 1em;
        // }

        // @media (min-width: 1900px) {
        //     font-size: 1.25em;
        //     padding: 0.75em;
        // }

        // @media (min-width: 3000px) {
        //     font-size: 3.5em;
        //     padding: 0.5em;
        // }

        &.active {
            background-color: #707070;
            color: white;

            &.increasing-consumption {
                background-color: #f15b29;
            }

            &.decreasing-consumption {
                background-color: #0b5e96;
            }

            &.normal-consumption {
                background-color: #fdc200;
            }

            .status-indicator {
                &.status-indicator-filter {
                    color: white;
                }
            }
        }

        &:first-of-type {
            border-left-width: 1px;
        }

        &:last-of-type {
            border-left-width: 0px;
        }
    }
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0vh;
    margin-right: 0vh;
    background-color: #eeeeef;

    .lds-grid {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-grid div {
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: rgb(248, 190, 34);
        animation: lds-grid 1.2s linear infinite;
    }

    .lds-grid div:nth-child(1) {
        top: 8px;
        left: 8px;
        animation-delay: 0s;
    }

    .lds-grid div:nth-child(2) {
        top: 8px;
        left: 32px;
        // top: 0.8vh;
        // left: 3.2vh;
        animation-delay: -0.4s;
    }

    .lds-grid div:nth-child(3) {
        top: 8px;
        left: 56px;
        // top: 0.8vh;
        // left: 5.6vh;
        animation-delay: -0.8s;
    }

    .lds-grid div:nth-child(4) {
        top: 32px;
        left: 8px;
        // top: 3.2vh;
        // left: 0.8vh;
        animation-delay: -0.4s;
    }

    .lds-grid div:nth-child(5) {
        top: 32px;
        left: 32px;
        // top: 3.2vh;
        // left: 3.2vh;
        animation-delay: -0.8s;
    }

    .lds-grid div:nth-child(6) {
        top: 32px;
        left: 56px;
        // top: 3.2vh;
        // left: 5.6vh;
        animation-delay: -1.2s;
    }

    .lds-grid div:nth-child(7) {
        top: 56px;
        left: 8px;
        // top: 5.6vh;
        // left: 0.8vh;
        animation-delay: -0.8s;
    }

    .lds-grid div:nth-child(8) {
        top: 56px;
        left: 32px;
        // top: 5.6vh;
        // left: 3.2vh;
        animation-delay: -1.2s;
    }

    .lds-grid div:nth-child(9) {
        top: 56px;
        left: 56px;
        // top: 5.6vh;
        // left: 5.6vh;
        animation-delay: -1.6s;
    }

    @keyframes lds-grid {

        0%,
        100% {
            opacity: 1;
        }

        50% {
            opacity: 0.5;
        }
    }

}