@import "src/styles/mixins.scss";

.area-list-container {
  height: 100%;

  .list-group {
    .area-list-item {
      position: static;
      font-size: 14px;

      @media (min-width: 3000px) {
        font-size: 2.57em;
      }

      .status-indicator-wrapper {
        font-size: 1em;
        height: 100%;
        width: 100%;
        padding: 0;
      }
    }

    height: 100%;
    overflow: auto;
    font-weight: bold;
    -ms-overflow-style: none;
    border-width: 0px;

    &.energy {
      border-top: 3px solid #f8be22;
      border-bottom: 3px solid #f8be22;
    }

    &.water {
      border-top: 3px solid #007c9f;
      border-bottom: 3px solid #007c9f;
    }

    .list-group-item {
      border-radius: 0px;
      padding: 0vh 0.1vh 0vh 0vh;
      cursor: pointer;
      border: 0px solid transparent;
      border-bottom: 1px solid #ddd;
      position: static;

      &.energy {
        color: #ababab;
      }

      &.wifi {
        background-color: #7e0cf5;
      }

      &.water {
        color: #007c9f;
      }

      .thead {
        display: flex;
        justify-content: space-between;

        &.table-head {
          padding: 9px 0;
          display: flex;

          @media (min-width: 3000px) {
            font-size: 2.57em;
          }

          .table-head-name,
          .list-head-value {
            flex: 1;
          }

          .table-head-name {
            flex: 2;
            display: grid;
            grid-template-columns: 32px 1fr;
            grid-gap: 10px;

            @media (min-width: 3000px) {
              grid-template-columns: 72px 1fr;
              grid-gap: 30px;
            }

            color: #94a2bf;

            .a {
              padding: 5px;
              font-size: 1em;
              height: 100%;
              width: 100%;
              padding: 0;
            }

            .b {
              padding: 5px;
              flex: 1;
            }
          }
        }

        position: sticky;
        top: 0;
      }

      .list-item-name-wrapper {
        position: static;
        display: grid;
        grid-template-columns: 32px 1fr;
        grid-gap: 10px;

        @media (min-width: 3000px) {
          grid-template-columns: 72px 1fr;
          grid-gap: 30px;
        }

        align-items: center;
        height: 100%;
      }

      .list-item-name {
        display: inline-block;
        padding: 5px;

        &.title {
          color: #ababab;
          font-weight: bold;
        }

        &.title:hover {
          background-color: #fff;
        }
      }

      .list-item-value {
        float: right;
        text-align: right;
        padding: 5px;

        &.title {
          color: #ababab;
          font-weight: bold;
        }
      }

      .list-head-name {
        float: left;
        padding: 5px;

        &.title {
          color: #94a2bf;
          font-weight: bold;
        }
      }

      .list-head-value {
        float: right;
        padding: 5px;
        text-align: right;

        &.title {
          color: #94a2bf;
          font-weight: bold;
        }

        &.equatorial-title {
          color: #7E91B2;
          text-transform: uppercase;
          font-weight: normal;
        }
      }
    }
  }

  .list-group::-webkit-scrollbar {
    display: none;
  }

  .list-group-item:last-child {
    border: 0px solid transparent !important;
  }
}

.equatorial-list-header {
  width: 100%;
  background-color: #5d5d5d;
  color: white;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 1.1rem;
}

.bigger-screen {
  display: block;

  @include for-collapsed-navbar {
    display: none;
  }
}

.smaller-screen {
  display: none;

  @include for-collapsed-navbar {
    display: block;
  }
}
