@import "../styles/mixins.scss";

.default-dashboard {
  height: 100%;
  width: 100%;

  .loading-overlay {
    height: 100%;

    &.detailed-energy-table {
      height: calc(80% - 3vh);
      bottom: 0;
      top: auto;
    }
  }

  .area-total {
    padding: 0 30px 30px 30px;

    @media(min-width: 3000px) {
      padding: 77px;
      padding-top: 0;
    }

    height: 100%;
    display: flex;
    flex-direction: column;

    // height: 90%;
    @include tabs-components {
      overflow-y: scroll;
    }

    .header {
      display: flex;
      padding-top: 9px;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      .header-column,
      .timetype-column,
      .link-to-liteme-wrapper {
        flex: 1;
      }

      .header,
      .components-title {
        flex: 1;

      }

      @include tabs-components {
        height: auto;
      }

      .timetype-column {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .button {
        padding: 10px 10px 10px 10px;
        margin-top: 10%;
        border: none;
        background-color: #e2ad33 !important;
        width: 100% !important;
        font-size: 0.8em;
        color: white;
        font-weight: normal;

        &.not-logged {
          color: rgb(248, 190, 34) !important;
          background-color: transparent !important;
          cursor: default !important;
          font-weight: bold;

          &:hover {
            background-color: transparent !important;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        &:hover {
          background-color: #f8be22 !important;
        }
      }
    }

    .components-title {
      font-size: 1.55em;
      margin: 25px 0 20px 0;
      // margin-top: -30px;

      // @media (min-width: 1200px){
      //   font-size: 1.5em;
      // }
      // @media (min-width: 1500px){
      //   font-size: 1.7em;
      // }
      // @media (min-width: 1900px) {
      //   font-size: 2.03em;
      // }
      // @media (min-width: 3000px) {
      //   font-size: 5.25em;
      // }

      font-weight: bold;
      color: #ababab;
      text-align: center;
    }

    .components {
      // @media (min-width: 1200px){
      //   font-size: 0.65em;
      // }
      // @media (min-width: 1900px) {
      //   font-size: 1em;
      // }
      // @media (min-width: 3000px) {
      //   font-size: 1em;
      // }
      display: flex;
      grid-gap: 30px;

      .chart {
        @media (min-width: 3000px) {
          .title {
            font-size: 1.5em;
          }

          .value energy {
            font-size: 5em;
          }
        }
      }

      .widget {
        flex: 4.5;
      }

      .area-col {
        height: calc((50vw - 120px)/4);
        flex: 11;

        .table-row {
          display: flex;

          div {
            flex: 1;
          }
        }

        
      }

      @media (max-width: 991px) {
        height: auto;
      }

      >div {
        @include tabs-components {
          height: 120px;
          margin-top: 10px;
        }
      }

      .compare-widget {
        display: flex;
        flex-direction: column;

        @include tabs-components {
          height: 210px !important;
          margin-top: 10px;
        }
      }
    }

    .dashboard-chart {
      margin-top: 25px;
      font-size: 1.55em; //OLHAR
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      min-height: 250px;

      div {
        width: 100%;
      }

      @include tabs-components {
        height: auto;
      }

      >div {
        @include tabs-components {
          height: 200px;
          margin-top: 10px;
        }
      }

    }

    .alert-widget-row {
      margin-top: 170px;
      height: 20%;

      @include tabs-components {
        height: auto;
      }
    }

    .list-row {
      height: calc(100% - 81px);

      @include for-phone-only {
        height: auto;
      }
    }
  }
}

.link-to-liteme-wrapper {
  margin: auto;
  display: flex;
  justify-content: right;

  #link-to-liteme {
    padding: 0;
  }

  .link-to-liteme {
    background-color: transparent;
    border: none;
    display: block;



    &:active {
      border: none !important;
      background-color: transparent !important;
      opacity: 0.7;
    }

    &:focus {
      box-shadow: none;
    }

    &:hover {
      opacity: 0.7;
    }

    .more-info-btn {
      width: 7.5vw;

      @include for-big-desktop-up {
        width: 7vw;
      }
    }
  }
}

.list-group-item:nth-child(even) {
  background-color: #f9f9f9
}

.area-list-item:hover {
  background-color: #f5edd7;
}