.rule-checkbox {
  display: inline-block !important;
  width: auto !important;
  padding: 10px 0px !important;
  // padding: 1vh 0vh !important;
}

.modal-title {
  width: 100%;
  text-align: center;
}

.modal-content {
  border: none;
  box-shadow: #00000088 0px 10px 15px;
  // box-shadow: #00000088 0vh 1vh 1.5vh;
  border-radius: 0;
}

.modal-body {
  overflow-y: scroll;
  // overflow: hidden;
  align-items: flex-start;
  max-height: 500px;
  // max-height: 50vh;

  .rule-header {
    margin: auto;
    background-color: #56968f;
    margin-bottom: 5px;
    // margin-bottom: 0.5vh;
    align-items: center;
    height: 60px;
    // height: 6vh;
    color: #FFFFFF;

    &.off {
      background-color: #E6E6E6;
      color: #707070;
    }
  }

  .switcher {
    align-self: center;
    font-size: 13px;
    // font-size: 1.3vh;
  }
}

.modal-open .modal {
  overflow-y: hidden;
}

.modal-footer {
  justify-content: center;

  .rules-button {
    background-color: #00796b !important;
    width: 100% !important;
    border: none;

    &:hover {
      background-color: #029685 !important;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.rules-wrapper {
  padding-bottom: 5vh !important;
  // width: 60vh;
}

.rules-list-header {
  padding: 5px;
  margin: 0;
  border-bottom: 1px solid #000000;
  // padding: 0vh 1vh 0vh 0vh;
  // margin: 0 ;
  // border-bottom: 0.1vh solid #000000;
  margin-bottom: 2vh;
  // font-size: 1.5vh;

  .rules-column-header {
    padding: 0;
    // padding: 1vh;
    text-align: center;

    &:first-child {
      text-align: left;
      flex: 1;
    }
  }
}

.no-rules-header {
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 1.8vh;
}