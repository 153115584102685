.settings-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .settings-tabs {
    display: flex;
    margin: 30px 0;

    .cat-btn {
      flex: 1;
      cursor: pointer;
      // font-size: 1.9vh;
      font-size: 1.2rem;
      color: #FFFFFF;
      background-color: #707070;
      // padding: 0.5vh 1vh;
      padding: 5px 10px;
      flex: 1;
      border: 1px solid #707070;
      // border: 0.1vh solid #707070;
      // border-top-width: 0.2vh;
      // border-bottom-width: 0.2vh;

      &.on {
        color: #FFFFFF;
        background-color: #707070;
      }

      &.off {
        background-color: #E6E6E6;
        color: #707070;
      }
    }

    .cat-btn:first-child {
      border-right-width: 0px;
    }

    .cat-btn:last-child {
      border-left-width: 0px;
    }
  }

  .settings-page-content {
    height: calc(100% - 60px - 41px);

    .add-user-row {
      padding-bottom: 15px;

      .add-user-col {
        display: flex;

        .add-user-btn {
          cursor: pointer;
          // font-size: 1.9vh;
          font-size: 1.2rem;
          margin: auto 0 auto auto;
          color: #FFFFFF;
          background-color: #707070;
          padding: 5px 10px;
        }
      }
    }

    .area-list,
    .categories-list {
      height: 100%;
      display: flex;
      flex-direction: column;
      border-top: 2px solid #303030;
      border-bottom: 2px solid #303030;
      border-radius: 0.25rem;
    }

    .area-list {
      .area-list-header,
      .building-header {
        padding: 0 15px;

        .name {
          flex: 3;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .class {
          flex: 1;
        }
      }

      .area-list-header {
        .name,
        .class {
          margin: 10px auto;
        }

        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        background-color: white;
      }

      .area-list-content {
        flex-grow: 1;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }

      .area-list-content::-webkit-scrollbar {
        display: none;
      }

      .building-header {
        margin: auto;
        background-color: #707070;
        margin-bottom: 5px;
        // margin-bottom: 0.5vh;
        align-items: center;
        justify-content: space-between;
        height: 45px;
        // height: 4.5vh;
        color: #FFFFFF;
        // font-size: 1.7vh;
        display: flex;
        padding: 0 15px;

        &.off {
          background-color: #E6E6E6;
          color: #707070;
        }

        select {
          width: 100%;
        }
      }

      .building-header:last-child {
        margin-bottom: 0px;
      }
    }

    .categories-list {
      height: calc(100% - 39px - 30px);

      .categories-list-header {
        padding: 5px 15px;
        font-size: 1.15em;
      }

      .categories-list-content {
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        color: #707070;

        .categories-list-row {
          padding: 5px 15px;
        }
      }

      .categories-list-content::-webkit-scrollbar {
        display: none;
      }
    }
  }
}