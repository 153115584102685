.consumption-chart-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title-container {
    .chart-title {
      font-weight: bold;
      text-align: center;
      color: #ababab;
      height: fit-content;
    }
  }

  .chart-container {
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .chart-column {
      height: 100%;
      width: 100%;
      position: absolute;

      div {
        height: 100%;
      }
    }

    .chart-error-text {
      color: #ababab;
      text-align: center;
    }

  }

  .historic {
    font-size: 1em;

    .subtitle {
      font-size: 0.8em;
    }
  }

}