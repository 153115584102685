.date-widget-container {
  height: 100%;
  display: flex;
  align-items: bottom;

  .label {
    display: flex;
    grid-gap: 2px;
    color: #94a2bf;
    color: #ababab;
    font-size: 1.2em;
    font-weight: bold;
    align-items: center;
    cursor: pointer;
    font-size: 1.5em;
    margin-top: 0;

    @media (min-width: 3000px) {
      font-size: 4em;
    }
  }

  .date-widget-value {
    text-align: center;
    font-weight: bold;
    margin-left: 0.6vh;
    color: #94a2bf;
  }

  @media (max-width: 1920px) {
    .date-widget-value {
      margin-left: 0.3vh;
    }
  }

  .react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #fff;
    color: #000;
    border: 0.1vh solid #aeaeae;
    border-radius: 0.3rem;
    top: -3.5vh;
    left: -7vh;
  }

  .react-datepicker__input-container {
    display: none;
  }

  .react-datepicker__month-container {
    display: contents;
    font-size: 1.2vh;
  }

  .react-datepicker__day {
    cursor: pointer;
    margin: 1vh;
  }

  .react-datepicker__day-name,
  .react-datepicker__day-names {
    margin: 0.3vh 1vh;
    display: inline-flex;
  }

  .react-datepicker__current-month {
    font-size: 1.3vh;
  }

  .react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    z-index: 1;
    height: 1vh;
    width: 1vh;
    text-indent: -999em;
    overflow: hidden;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 0.5rem;
  }
}