@import "src/styles/mixins.scss";

.equatorial-energy-container {
  padding: 0;
  margin-top: 9px;
  height: 100vh;
  overflow: hidden;

  .row {
    margin: 0;
  }
}

.equatorial-detailed-filters {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  font-weight: bold;
  padding: 0;

  .detailed-filter-wrapper {
    background-color: #eeeeef;
    display: flex;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #707070;
    border-left-width: 0px;
    border-bottom-width: 1px;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #707070;

    &.active {
      background-color: #707070;
      color: white;

      &.increasing-consumption {
        background-color: #f15b29;
      }

      &.decreasing-consumption {
        background-color: #0b5e96;
      }

      &.normal-consumption {
        background-color: #fdc200;
      }

      .status-indicator {
        &.status-indicator-filter {
          color: white;
        }
      }
    }

    &:first-of-type {
      border-left-width: 1px;
    }

    &:last-of-type {
      border-left-width: 0px;
    }
  }

  .list-search {
    font-size: 1rem;
    margin-top: 0;
    height: auto;
    padding: 0;

    .list-search-label {
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;

      .list-search-icon {
        position: absolute;
        margin: auto auto auto 0.7vw;
        color: #707070;
      }

      .list-search-input {
        padding-left: 2.3vw;
        border-radius: 0;
        border: 1px solid #707070;
        width: 100%;
        height: 100%;

        &:focus {
          border-radius: 0;
          border: 2px solid #707070;
          outline: none;
        }
      }
    }
  }

  .row {
    margin: 0;
    width: 100%;
  }

  .row:first-child {
    margin-bottom: 15px;
  }
}

.table-detailed {
  flex-grow: 1;
  overflow: auto;

  .table-detailed-row {
    display: flex;
    align-items: center;
    height: 100%;

    .list-item-consumption {
      flex: 1;
    }

    .list-item-name-wrapper {
      flex: 2;
    }

    @media (min-width: 3000px) {
      .status-indicator-wrapper {
        font-size: 36px;
      }
    }
  }
}

.status-indicator-wrapper {
  margin-right: 0.5vh;
  padding: 1vh 1vh 1vh 1vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &.status-indicator-wrapper-filter {
    margin: auto;
    padding: 0;
    height: fit-content;
  }

  &.out-of-service {
    background-color: #f15b29;
  }

  &.invalid,
  &.normal {
    background-color: #0b5e96;
  }

  &.has-alerts {
    background-color: #fdc200;
  }

  &.offline {
    background-color: #d2d4d2;
    border: 1px solid black;
  }

  .status-indicator {
    font-size: 1em;
    color: white;
    margin: auto;
    display: flex;
    align-items: center;

    &.offline {
      color: black;
    }

    &.status-indicator-filter {
      color: #707070;
    }
  }
}

.equatorial-measurement-wrapper {
  text-align: center;
  padding: 0;
  margin: auto;

  .equatorial-measurement-filter-wrapper {
    padding: 0;
    margin: auto;
    cursor: pointer;

    .equatorial-measurement-label {
      padding: 0;
      margin: auto;
      text-align: left;
      font-size: 0.8rem;
      font-weight: lighter;
      overflow-x: hidden;
      text-overflow: ellipsis;

      &.active {
        font-weight: bold;
      }

      @include for-collapsed-navbar {
        display: none;
      }
    }

    .equatorial-measurement-logo {
      transform: scale(1.2);
      padding: 0;
      margin: auto;

      @include for-collapsed-navbar {
        transform: scale(1.6);
      }
    }
  }
}

.equatorial-energy-time-type-selector {
  transform: scale(0.8);
  padding: 0;
}

.equatorial-energy-time-type-date {
  display: flex;
  color: #94a2bf;
  color: #ababab;
  font-size: 1.2em;
  font-weight: bold;
  font-size: 1.5em;
  margin-top: 5px;
  padding-left: 10px;

  @media (min-width: 3000px) {
    font-size: 4em;
  }
}

.equatorial-energy-link-liteme {
  flex: 1;
  text-align: right;
  padding-right: 0;

  @include for-collapsed-navbar {
    transform: scale(1.75);
    padding: 0;
  }
}

.equatorial-energy-components-title {
  justify-content: center;
  margin: 2vh 0 0 0 !important;
}

.equatorial-energy-timestamp {
  font-size: 1.15em;
  font-weight: bold;
  color: #ababab;
  margin: 0 0 20px 0 !important;
  justify-content: center;
}

.equatorial-energy-widgets-wrapper {
  height: 65vh;
}

.equatorial-energy-graph-wrapper {
  height: 38vh;
  padding-top: 2vh;

  @include for-collapsed-navbar {
    padding-top: 5vh;
    height: auto;
    display: flex;
    align-items: end;
    transform: scale(1.3);
  }
}

.equatorial-energy-graph {
  width: 100%;
  padding-top: 2vh;
}

.equatorial-chart-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1vh;
  padding-top: 2vh;
  padding-bottom: 0;

  .total {
    color: #f8be22;
  }

  .neutral {
    color: #0178ff;
  }

  .phase-a {
    color: #000000;
  }

  .phase-b {
    color: #f21313;
  }

  .phase-c {
    color: #a67646;
  }
}

.detailed-filter-text {
  &.bigger-screen {
    display: block;
  }
  &.smaller-screen {
    display: none;
  }

  @include for-collapsed-navbar {
    &.bigger-screen {
      display: none;
    }
    &.smaller-screen {
      display: block;
    }
  }
}
