@import "../../styles/mixins.scss";

.nav {
  font-family: Ubuntu;
  font-size: 16px;
  z-index: 1;

  @media (min-width: 3000px) {
    font-size: 2.57em;
  }

  border-bottom: 2px solid #7d7d7d;
  background-color: #eeeeef;
  justify-content: flex-start;
  align-content: center;
  width: 100%;

  @include for-collapsed-navbar {
    height: 7vh;
    justify-content: space-between;

    .navbar-toggler {
      border-color: #FFFFFF;
    }
  }

  &.litecampus-alt {
    background-color: #4c1198;
    border-bottom: 2px solid #4c1198;

    .nav-link {
      color: #FFFFFF;

      &:hover {
        color: #FFFFFF;
        font-size: 18px;
        line-height: 18px;
      }
    }

    .navbar-collapse {
      background-color: #4c1198;
    }
  }

  .navbar-collapse {
    background-color: #eeeeef;
    z-index: 1;

    @include for-collapsed-navbar {
      padding: 27.5vh 0 3vh 3vh;
      z-index: -1;
      position: fixed;
      width: 96vw;
    }
  }

  .row {
    width: 100%;
    .login-button {
      .navbar-nav {
        float: right;
      }
    }
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .item {
      font-size: 12px;
      text-align: center;
      margin-top: auto;
      margin-bottom: auto;
      cursor: pointer;
    }
  }
  .logo {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    margin-left: 10px;
    height: 42px;
    aspect-ratio: 3/1;
    background-image: url("../../assets/img/litecampus.svg");

    &.ufcg {
      background-image: url("../../assets/img/logo.png");
      background-image: url("../../assets/img/liteme-maps.svg");
    }

    &.litecampus-alt {
      background-image: url("../../assets/img/litecampus-alt.svg");
    }

    &.liteme-maps {
      background-image: url("../../assets/img/liteme-maps.svg");
    }

    &.pmcg-alt {
      background-position: center;
      background-image: url("../../assets/img/pmcg-alt.svg");
    }

    &.equatorial {
      background-position: center;
      background-image: url("../../assets/img/equatorial.svg");
    }

    @include for-phone-only {
      height: 50px;
      background-position: center;
      margin-left: 0px;
    }
  }
  .menu-icon {
    height: 30px;
    width: 30px;
    margin: 10px;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../assets/img/menu-button.png");
  }
  .nav-link {
    font-weight: bold;
  }
}
