.alert-widget {
  padding: 1vh;
  .title-container {
    height: 15%;
    .list-title {
      font-size: 1.2vh;
      font-weight: bold;
      text-align: center;
      color: #ababab;
      margin-bottom: 1vh;
    }
    .button {
      background-color: #e2ad33;
      border: none;
      position: absolute;
      right: 5vh;

      &:hover {
        background-color: #f8be22;
      }

      &:active {
        background-color: #e2ad33 !important;
      }

      &:focus {
        background-color: #e2ad33;
        outline: none;
        box-shadow: none !important;
      }
    }
  }
}
