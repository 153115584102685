.actuator-list {
  .actuators {
    padding-top: 10px;
  }

  .filters {
    padding: 20px 0px 10px 0px;
    margin: 0;
    border-bottom: 2px solid #303030;
    display: flex;

    .filter:first-child {
      padding-left: 0;
    }

    .a {
      display: flex;
      align-items: center;

      @media (min-width: 1200px){
        grid-gap: 10px;

      }

      @media (min-width: 1900px) {
        grid-gap: 7px;

      }
      @media (min-width: 3000px) {
        grid-gap: 30px;
      }

      .label {
        text-align: end;
        margin-top: auto;
        // @media (min-width: 1200px){
        //   margin-top: 0.1em;
        //   font-size: 1em;      
        // }
        // @media (min-width: 1900px) {
        //   margin-top: 0.1em;
        //   font-size: 1.3em;
        // }
        // @media (min-width: 3000px) {
        //   margin-top: 0em;
        //   font-size: 3em;
        // }
      }
  
      .rules {
        border-radius: 0;
        padding: 4.75px 17px;
        color: #ffffff !important;
        border: 1px solid #00796b !important;
        background-color: #00796b !important;
        display: flex;
        flex-direction: column;
        grid-gap: 17px
        // @media (min-width: 1200px){
        //   font-size: 0.7em;
        //   grid-gap: 10px;
  
        // }
        // @media (min-width: 1500px){
        //   font-size: 1.2em;
        //   padding: 0px 10px 0px 10px !important;
  
        // }
        // @media (min-width: 1900px) {
        //   font-size: 1.5em;
        //   grid-gap: 7px;
  
        // }
        // @media (min-width: 3000px) {
        //   font-size: 3em;
        //   grid-gap: 30px;
        // }
      }

    }

    
  }

  .building-title {
    font-size: 3vh;
    color: #294580;
    opacity: 0.5;
  }
}
