.alert-form {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-items: stretch !important;
  padding: 10px;

  // padding: 1vh;
  & input {
    // height: 3.5vh;
    // position: relative;
    // top: 2vh;
    // margin: 0vh  0vh 2vh 0vh;
    width: 100% !important;
  }

  select {
    background-color: white;
    font-size: 18px;
    padding: 10px 10px 10px 2px;
    // font-size: 1.8vh;
    // padding: 1vh 1vh 1vh 0vh;
    display: block;
    width: 100% !important;
    border: none;
    border-bottom: 1.5px solid #d1d1d1;
    // margin-top: 2vh;
    // border-bottom: 0.15vh solid #d1d1d1;

    &:focus {
      outline: none;
    }
  }

  // .action {
  //   color: #555;
  //   font-size: 1.4vh;
  //   margin-top: 0.7vh;
  //   margin-bottom: 1.5vh;
  //   font-weight: bold;
  //   pointer-events: none;
  //   left: 3vh;
  //   transition: 0.2s ease all;
  //   -moz-transition: 0.2s ease all;
  //   -webkit-transition: 0.2s ease all;

  // }

  & .placeholder {
    // margin-top: 2vh;
    color: #d1d1d1;
  }

  & .validOption {
    color: #000;
    // font-size: 100%;
  }

  & .hidden {
    display: none;
  }

  & .group label {
    color: #555;
    font-size: 14px;
    // font-size: 1.4vh;
    font-weight: bold;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 50px;
    // left: 0.5vh;
    // top: 5vh;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  & .time {
    width: 40% !important;
    height: 40% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
  }

  & .button {
    background-color: #e2ad33 !important;
    width: 100% !important;

    &:hover {
      background-color: #f8be22 !important;
    }
  }
}

.alert-form::-webkit-scrollbar {
  display: none;
}

.modal-title {
  padding: 10px;
  // padding: 1vh;
}

.modal-content {
  border: none;
  box-shadow: #00000088 0px 10px 15px;
  // box-shadow: #00000088 0vh 1vh 1.5vh;
  border-radius: 0;
  // height: 30vh;
  // width: 30vh;
}