@import "../../styles/mixins.scss";

.side-menu-container {
  display: flex;
  float: left;
  font-family: Ubuntu;
  // font-size: 1.9vh;

  a {
    color: inherit;
    transition: 0.2s;
    // font-size: 1.5vh;
  }

  a:hover {
    color: black;
    text-decoration: none;
    font-weight: bold;
  }

  .left-column {
    float: left;
    width: 5vw;
    height: 100%;
    z-index: 1000;
    background-color: #eeeeef;
    border-right: 2px solid #000000;
    // border-right: 0.2vh solid #000000;

    @include for-phone-only {
      width: 10vw;
    }
  }

  .right-column {
    position: relative;
    float: left;
    min-width: 45vw;
    max-width: 45vw;
    height: 100%;
    background-color: white;
    transition: margin 0.5s ease-out;

    @media (max-width: 1500px) {
      min-width: 57vw;
      max-width: 57vw;
    }

    @include for-phone-only {
      min-width: 65vw;
      max-width: 65vw;
    }

    &.closed {
      margin-left: -45vw;

      @include for-phone-only {
        margin-left: -65vw;
      }
    }

    .right-column-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;

      .header-container {
        @include for-phone-only {
          height: 10%;
        }

        .header {
          height: 50%;
          background-color: #eeeeef;
          border-right: 2px solid #7d7d7d;
          border-bottom: 2px solid #7d7d7d;
          // border-right: 0.2vh solid #7d7d7d;
          // border-bottom: 0.2vh solid #7d7d7d;

          @include for-phone-only {
            height: 70%;
          }

          .item {
            font-size: 10px;
            // font-size: 1vh;
            text-align: center;
            margin-top: auto;
            margin-bottom: auto;
            cursor: pointer;
          }
        }

        .change-type-container {
          .header-title .header-tabs {
            @media (min-width: 4000px) {
              font-size: 12rem;
            }
          }

          height: 100%;
          padding: 0 30px;
          border-right: 2px solid #7d7d7d;
          transition: background-color 0.5s;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;

          @include for-phone-only {
            height: 100%;
          }

          .header-title {
            @media (min-width: 4000px) {
              font-size: 4rem;
            }
            font-size: 25px;
            padding: 5px 0px;

            color: white;
            font-weight: bold;

            @include for-phone-only {
              font-size: 14px;
              // font-size: 1.4vh;
            }
          }

          .header-tabs {
            height: 100%;
          }

          .change-type-button {
            @media (min-width: 4000px) {
              width: 80px;
            }

            width: 30px;
            // width: 85px;
            height: 100%;
            margin-left: 5px;
            // margin-right: 0.5vh;

            float: right;
            cursor: pointer;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            -webkit-mask-image: url(../../assets/img/hexagon.svg);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: contain;
            -webkit-mask-position: center;
            mask-image: url(../../assets/img/hexagon.svg);
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-position: center;

            @include for-phone-only {
              width: 24px;
            }

            &.active {
              &.energy {
                background-image: url("../../assets/img/energy-active.png") !important;
              }

              &.water {
                background-image: url("../../assets/img/water-active.png") !important;
              }

              &.wifi {
                background-image: url("../../assets/img/wifi-active.png") !important;
              }

              &.mobsense {
                background-image: url("../../assets/img/mobsense-active.png") !important;
              }

              &.objectDetector {
                background-image: url("../../assets/img/objectDetector-active.png") !important;
              }

              &.alerts {
                background-image: url("../../assets/img/no-alerts-active.svg") !important;

                &.has-alerts {
                  background-image: url("../../assets/img/alerts-active.svg") !important;
                }
              }

              &.actuators {
                background-image: url("../../assets/img/actuators-active.svg") !important;
              }

              &.settings {
                background-image: url("../../assets/img/settings-active.svg") !important;
              }

              -webkit-mask-image: none;
              mask-image: none;
              background-color: transparent !important;
            }

            &.inactive {
              &.energy {
                background-image: url("../../assets/img/energy-inactive.png") !important;
              }

              &.water {
                background-image: url("../../assets/img/water-inactive.png") !important;
              }

              &.wifi {
                background-image: url("../../assets/img/wifi-inactive.png") !important;
              }

              &.mobsense {
                background-image: url("../../assets/img/mobsense-inactive.png") !important;
              }

              &.objectDetector {
                background-image: url("../../assets/img/objectDetector-inactive.png") !important;
              }

              &.alerts {
                background-image: url("../../assets/img/no-alerts-inactive.svg") !important;

                &.has-alerts {
                  background-image: url("../../assets/img/alerts-inactive.svg") !important;
                }
              }

              &.actuators {
                background-image: url("../../assets/img/actuators-inactive.svg") !important;
              }

              &.settings {
                background-image: url("../../assets/img/settings-inactive.svg") !important;
              }

              -webkit-mask-image: none;
              mask-image: none;
              background-color: transparent !important;
            }
          }

          // @media (max-width: 1920px) {
          //   .change-type-button {
          //     width: 30px;
          //   }
          // }
        }
      }

      .body-container {
        flex-grow: 1;
        // height: 100%;
        background-color: white;
        border-right: 2px solid #7d7d7d;
        // border-right: 0.2vh solid #7d7d7d;
        overflow-y: auto;

        @include for-phone-only {
          height: 90%;
        }
      }
    }
  }
}
