.actuator-header {
  margin: 2.5px 0px;
  color: black;
  font-size: 0.9em;
  min-height: 60px;
  border-width: 3px;
  padding: 5px;
  background-color: #FFFFFF;
  color: #707070;
  display: flex;
  grid-gap: 15px;

  &.disabled-actuator {
    cursor: not-allowed;
  }

  .button {
    align-self: center;
    cursor: pointer;

    
    &.switcher {
      padding: 0;
      font-size: 1.3em;
      color: #00796b;

      &.disabled-actuator {
        color: #E6E6E6;
        cursor: not-allowed;
      }
    }

    &.rules {
      padding: 0;
      text-align: center;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      color: #FFFFFF;
      background-color: #00796b;
    
      &.disabled-actuator {
        color: #707070;
        background-color: transparent;
        cursor: not-allowed;
      }
    }
  }

  .text {
    overflow-y: auto;
    height: 100%;
    padding-left: 1vw;
    flex-grow: 1;

    .actuator-description {
      cursor: pointer;
      font-size: 1rem;
    }
    .actuator-identifier {
      font-size: 0.75rem;
    }    
  }
  .set-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    .sign {
      margin-right: 10px;
    }

    .info {
      font-size: 1.1rem;
      cursor: pointer;
    }
  }
}