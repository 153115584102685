.building-checkbox {
  display: inline-block !important;
  width: auto !important;
  padding: 10px 0px !important;
  // padding: 1vh 0vh !important;
}

.modal-title {
  width: 100%;
  text-align: center;
  // font-size: 1.9vh;
}

.modal-content {
  border: none;
  box-shadow: #00000088 0px 10px 15px;
  // box-shadow: #00000088 0vh 1vh 1.5vh;
  border-radius: 0;
}

.modal-body {
  align-items: flex-start;
  .search-row {
    margin: 1rem;
    // margin: 1.6vh;
    width: 95%;
    margin: auto;
    margin-top: 1vh;
    margin-bottom: 2vh;

    .buildings-list-search {
      width: 100%;
      height: 4vh;
      font-size: 1.2rem;
      // font-size: 1.9vh;
  
      .buildings-list-search-label {
        height: 4vh;
        width: 100%;
        display: grid;
  
        .buildings-list-search-icon {
          height: 4vh;
          position: absolute;
          margin: auto auto auto 0.7vw;
          color: #707070;
        }
        .buildings-list-search-input {
          width: 100%;
          padding: 0;
          padding-left: 2.7vw;
          border-radius: 0;
          border: 1px solid #707070;
          // border: 0.1vh solid #707070;
  
          &:focus {
            border-radius: 0;
            border: 2px solid #707070;
            // border: 0.2vh solid #707070;
            outline: none;
          }
        }
      }
    }
  }

  .buildings-list {
    overflow-y: scroll;
    max-height: 350px;
    // max-height: 35vh;

    .building-header {
      width: 95%;
      margin: auto;
      background-color: #707070;
      margin-bottom: 5px;
      // margin-bottom: 0.5vh;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      // height: 4.5vh;
      color: #FFFFFF;
      &.off {
        background-color: #E6E6E6;
        color: #707070;
      }
    }
  
    .switcher {
      align-self: center;
    }
  }
}

.modal-open .modal {
  overflow-y: hidden;
}

.modal-footer {
  justify-content: center;

  .update-button {
    background-color: #707070 !important;
    width: 100% !important;
    border: none;
    border-radius: 0;
  
    &:hover {
      background-color: #575757 !important;
    }
  
    &:focus {
      box-shadow: none;
    }
  }
}

.modal-header {
  .close {
    &:focus {
      outline: none;
    }
  }
}
.read-only-user-row {
  width: 95%;
  margin: auto;
  justify-content: flex-end;

  .text-wrapper {
    display: flex;
    height: auto;

    &.on {
      color: #FFFFFF;
      background-color: #707070;
    }
  
    &.off {
      background-color: #E6E6E6;
      color: #707070;
    }

    .text {
      // font-size: 1vh;
      margin: auto;
    }
  }

  .switcher {
    padding-top: 5px;
    padding-bottom: 5px;
    // padding-top: 0.5vh;
    // padding-bottom: 0.5vh;
    
    &.on {
      color: #FFFFFF;
      background-color: #707070;
    }
  
    &.off {
      background-color: #E6E6E6;
      color: #707070;
    }
  }
}