.area-header-container {
  font-weight: bold;
  .area-upper-name {
    color: #284480;
    font-size: 32px;
    margin-right: 1rem;

    @media (min-width: 3000px) {
      font-size: 2.57em;
    }


    cursor: pointer;
    .area-upper-date {
      color: #284480;
      font-size: 2vh;
    }
  }
  
  .area-name {
    color: #94a2bf;
    font-size: 1em;

    @media (min-width: 3000px) {
      font-size: 2.57em;
    }
  }

  .back-icon {
    margin-right: 10px;
    cursor: pointer;
  }
}
