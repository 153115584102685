@import "src/styles/mixins.scss";

.eq-hex {
  width: 100%;
  height: calc((40vw - 120px) / 4);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: table;
  font-size: 1em;

  @include for-collapsed-navbar {
    height: 15vw;
  }

  &.total {
    background-image: url("../../../../assets/img/equatorial/total.svg");
    .hex-value {
      color: #f8be22;
    }
  }

  &.neutral {
    background-image: url("../../../../assets/img/equatorial/neutral.svg");
    .hex-value {
      color: #0178ff;
    }
  }

  &.phase-a {
    background-image: url("../../../../assets/img/equatorial/phase-a.svg");
    .hex-value {
      color: #000000;
    }
  }

  &.phase-b {
    background-image: url("../../../../assets/img/equatorial/phase-b.svg");
    .hex-value {
      color: #f21313;
    }
  }

  &.phase-c {
    background-image: url("../../../../assets/img/equatorial/phase-c.svg");
    .hex-value {
      color: #a67646;
    }
  }

  .hex-value {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: calc((25vw - 120px) / 16);

    @include for-collapsed-navbar {
      font-size: 1.2em;
    }

    .hex-unity {
      margin: 0;
      font-weight: bold;
      font-size: 0.6em;
    }
  }

  @media (min-width: 3000px) {
    .hex-unity {
      font-size: 0.7em;
    }

    .hex-value {
      font-size: 6em;
    }
  }
}

.eq-label {
  margin-top: 10px;
  text-align: center;

  .title {
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;

    @include for-collapsed-navbar {
      margin-bottom: 1vh;
    }

    @media (min-width: 1200px) {
      font-size: 1.2em;
    }

    @media (min-width: 1900px) {
      font-size: 1.34em;
    }

    @media (min-width: 3000px) {
      font-size: 3em;
    }

    &.total {
      color: #f8be22;
    }

    &.neutral {
      color: #0178ff;
    }

    &.phase-a {
      color: #000000;
    }

    &.phase-b {
      color: #f21313;
    }

    &.phase-c {
      color: #a67646;
    }
  }
}
