.time-type-selector-container {
  button {
    background-color: #94a2bf;
    border-radius: 3px;
    border-color: #94a2bf;

    &.selected {
      background-color: white;
      border-width: 5px;
      color: #284480;
      font-weight: bold;
      cursor: pointer;
      pointer-events: none;
    }
  }

  button:hover {
    background-color: #94a2bf;
    border-color: #94a2bf;
    color: white;
  }

  button:focus {
    box-shadow: none;
  }

  button:not(:disabled):not(.disabled):active {
    background-color: #94a2bf;
    border-color: #94a2bf;
    box-shadow: none;
  }

  @media (min-width: 1200px){
    button {
      font-size: 1em;
      padding: 0.2em 1em;
    }
  }
  @media (min-width: 1900px) {
    button {
      font-size: 1em;
      padding: 0.2em 1em;
    }
  }
  @media (min-width: 3000px) {
    button {
      font-size: 3em;
      padding: 0.2em 1em;
    }
  }
}