@import "../../styles/mixins.scss";

.login,
.modal {
  height: 92.5vh;

  * {
    box-sizing: border-box;
  }

  .return {
    color: #888;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px;
    // padding: 1vh;
    transition: 0.2s;

    a {
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      color: black;
    }
  }

  .img {
    width: 600px;
    // width: 60vh;

    @include for-phone-only {
      width: 300px;
    }
  }

  .container {
    width: 80vw;
    height: 80vh;
    margin: 30px auto 0;
    // margin: 3vh auto 0;
    display: flex;
    background: #fff;
    padding: 10px 50px 50px;
    // padding: 1vh 5vh 5vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h2 {
    text-align: center;
    margin-bottom: 50px;
    // margin-bottom: 5vh;
    color: #888;
    font-weight: normal;
  }

  h2 small {
    font-weight: normal;
    color: #888;
    display: block;
  }

  .group {
    position: relative;
    margin-bottom: 45px;
    // margin-bottom: 4.5vh;
  }

  input {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    // font-size: 1.8vh;
    // padding: 1vh 1vh 1vh 0.5vh;
    display: block;
    width: 300px;
    // width: 30vh;
    border: none;
    border-bottom: 1.5px solid #d1d1d1;
    // border-bottom: 0.15vh solid #d1d1d1;

    &:focus {
      outline: none;
    }
  }

  label {
    color: #d1d1d1;
    font-size: 18px;
    // font-size: 1.8vh;
    font-weight: bold;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    // left: 0.5vh;
    // top: 1vh;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  input:focus~label,
  input:valid~label {
    top: 50px;
    font-size: 14px;
    // top: 0.5vh;
    // font-size: 1.4vh;
    color: #555;
  }

  .bar {
    position: relative;
    display: block;
    width: 300px;
    // width: 30vh;
  }

  .bar:before,
  .bar:after {
    content: "";
    height: 2px;
    // height: 0.2vh;
    width: 0;
    bottom: 1px;
    // bottom: 0.1vh;
    position: absolute;
    background: #555;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .bar:before {
    left: 50%;
  }

  .bar:after {
    right: 50%;
  }

  input:focus~.bar:before,
  input:focus~.bar:after {
    width: 50%;
  }

  .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    // width: 10vh;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  input:focus~.highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }

  @-webkit-keyframes inputHighlighter {
    from {
      background: #555;
    }

    to {
      width: 0;
      background: transparent;
    }
  }

  @-moz-keyframes inputHighlighter {
    from {
      background: #555;
    }

    to {
      width: 0;
      background: transparent;
    }
  }

  @keyframes inputHighlighter {
    from {
      background: #555;
    }

    to {
      width: 0;
      background: transparent;
    }
  }

  .button {
    border: solid 1px #d1d1d1;
    // border: solid 0.1vh #d1d1d1;
    border-radius: 0;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 30px;
    // padding: 1vh 3vh;
    background: #3cb0ef;
    font-size: 14px;
    // font-size: 1.4vh;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  // .close {
  //   float: right;
  //   font-size: 3vh;
  //   margin-left: 0.5vh;
  //   color: #000;
  //   opacity: .5;
  // }
}