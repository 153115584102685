.list {
  position: relative;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .content_container {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex: 1;

    & > * {
      margin: 10px;
    }
  }

  .prev,
  .next {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    cursor: pointer;
    transition: 0.6s ease;
  }

  .next {
    margin-left: 5px;
    &.energy {
      color: #f8be22;
    }
  }

  .prev {
    margin-right: 5px;
  }

  ::-webkit-scrollbar {
    height: 0;
    background: transparent;
  }

  &.energy {
    .prev,
    .next {
      color: #f8be22 !important;
    }
  }
  &.water {
    .prev,
    .next {
      color: #007c9f !important;
    }
  }
}
