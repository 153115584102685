.user-list {
  display: flex;
  flex-direction: column;
  height: calc(100% + 15px);

  .filters {
    margin: 0;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .list-filter {
      margin-right: 15px;
    }

    .filter:first-child {
      padding-left: 0;
    }

    .list-search {
      width: 100%;
      height: 100%;
      font-size: 1rem;

      // font-size: 1.6vh;

      .list-search-label {
        height: 100%;
        width: 100%;
        display: grid;

        align-items: center;

        .list-search-icon {
          position: absolute;
          margin: auto auto auto 0.7vw;
          color: #707070;
        }

        .list-search-input {
          padding-left: 2.3vw;
          border-radius: 0;
          border: 1px solid #707070;
          // border: 0.1vh solid #707070;
          height: 100%;

          &:focus {
            border-radius: 0;
            border: 2px solid #707070;
            // border: 0.2vh solid #707070;
            outline: none;
          }
        }
      }
    }
  }

  .user-list-header {
    margin: 0;
    padding: 5px;
    font-size: 1.2rem;
    // padding: 0.5vh;
    // font-size: 1.9vh;
    display: flex;    
    border-top: 2px solid #303030;

    .auth-header {
      display: block;
      text-align: center;
    }

    .user {
      flex: 7;
    }

    .authorization {
      flex: 4;
      text-align: center;
    }

    .edit {
      flex: 1;
      text-align: center;
    }
  }

  .users {
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .users::-webkit-scrollbar {
    display: none;
  }
}