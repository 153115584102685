.gradient-rule-container {
    height: 260px;
    width: 106px;
    position: absolute;
    left: 10px;
    bottom: 30px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    padding: 5px;
    padding-top: 5px;
    padding-bottom: 5px;

    .gradient-rule-steps {
        height: 100%;
        width: 76px;
        .step {
            height: 14.1%;
    
            .step-label {
                margin-top: -2px;
                float: left;
                font-size: 12px;
                padding-right: 4px;
                width: 85%;
                text-align: right;
            }

            .step-color {
                float: right;
                background-color: black;
                height: 100%;
                width: 15%;
            }
        }
        .gradient-rule-footer {
            position: absolute;
            bottom: 6px;
            font-size: 12px;
            left: 15px;
        }
    }

    .gradient-rule-title {
        transform: rotate(-90deg);
        position: absolute;
        bottom: 35px;
        left: 60px;
        color: #c1c2c3;
    }
  }
