.building-checkbox {
  display: inline-block !important;
  width: auto !important;
  padding: 10px 0px !important;
  // padding: 1vh 0vh !important;
}

.modal-title {
  width: 100%;
  text-align: center;
}

.modal-content {
  border: none;
  box-shadow: #00000088 0px 10px 15px;
  // box-shadow: #00000088 0vh 1vh 1.5vh;
  border-radius: 0;
  height: 50%;

  @media (min-width: 1200px) {
    .modal-header {
      .close {
        font-size: 2em;
      }
    }

    .modal-body {
      width: content;
    }
  }

  @media (min-width: 1500px) {
    .modal-header {
      .close {
        font-size: 2em;
        padding: 0.5 1.5 0 0em;
      }
    }

    .modal-body {
      width: content;
    }
  }

  @media (min-width: 1900px) {
    font-size: 1.5em;
    padding: 0px 7px 0px 7px !important;

  }

  @media (min-width: 3000px) {
    .modal-header {
      .close {
        font-size: 1.5em;
      }
    }

    .modal-body {
      width: content;
      height: content;
    }
  }
}

.modal-body {
  align-items: flex-start;

  .new-user-row {
    margin: 1rem;
    // margin: 1.6vh;
    width: 95%;
    margin: auto;
    margin-top: 1vh;
    margin-bottom: 4vh;

    &.agree {
      flex-wrap: unset;
      margin-bottom: 0vh;
    }

    .agree-checkbox {
      width: 25px;
      margin-top: 5px;
      // width: 2.5vh;
      // height: 2.5vh;
      // margin-top: 0.5vh;
    }

    .agree-text {
      margin-left: 10px;
      // margin-left: 1vh;
      // font-size: 1.6vh;
    }

    &.submit-btn {
      margin-top: 3vh;
      margin-bottom: 2vh;

      .submit-btn {
        margin: auto;
        background-color: #707070 !important;
        width: 100% !important;
        border: none;
        border-radius: 0;
        // font-size: 1.2vh;

        &:hover {
          background-color: #575757 !important;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .new-user-field {
      width: 100%;
      height: 4vh;
      font-size: 1rem;
      // font-size: 1.6vh;

      .new-user-field-label {
        height: 4vh;
        width: 100%;
        display: grid;
        color: #707070;

        .new-user-field-input {
          width: 100%;
          padding: 0;
          border-radius: 0;
          border-bottom: 1px solid #707070;
          font-size: 1.4rem;
          // border-bottom: 0.1vh solid #707070;
          // font-size: 2.2vh;

          &:focus {
            border-radius: 0;
            border-bottom: 2px solid #707070;
            // border-bottom: 0.2vh solid #707070;
            outline: none;
          }
        }
      }
    }
  }

  .new-user-form-error {
    width: 95%;
    margin: auto;
    color: #f35456;
    margin-bottom: 2vh;
    margin-top: -1vh;
    font-size: 0.9rem;
    // font-size: 1.4vh;
  }
}

.modal-open .modal {
  overflow-y: hidden;
}

.modal-header {
  .close {
    &:focus {
      outline: none;
    }
  }
}

.swal-text {
  text-align: center;
  padding-top: 2vh;
  // font-size: 2vh;
}

// .swal-modal {
//   width: auto;
//   height: auto;
//   border-radius: 0.5vh;
// }

// .swal-title {
//   font-size: 2vh;
// }

// .swal-button {
//   width: 100%;
//   height: 100%;
//   font-size: 1.5vh;
//   margin: 0.2vh;
// }

// .swal-footer {
//   text-align: center;
// }