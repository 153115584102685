.legend-container {
  height: 260px;
  width: 106px;
  position: absolute;
  left: 10px;
  bottom: 30px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  padding: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  &.four-colors {
    height: 350px;
  }

  .legend-colors {
    height: 100%;
    width: 85px;
    .color {
      padding: 10px;
      height: 33%;

      &.four-colors {
        height: 25%;
      }
    

      .color-label {
        margin-top: -2px;
        float: left;
        font-size: 12px;
        padding-right: 4px;
        width: 100%;

        span {
          font-weight: bold;
        }
      }

      .step-color {
        width: 100%;
        float: left;
        padding-bottom: 5px;
      }
    }
  }

  .legend-title {
    transform: rotate(-90deg);
    position: absolute;
    bottom: 53px;
    left: 40px;
    color: #c1c2c3;
    width: 100%;
  }
}

.legend-switch-container {
  height: 1.5rem;
  width: 230px;
  position: absolute;
  left: 13px;
  bottom: 133px;
  background-color: #eeeeee;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  padding: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  transform: rotate(270deg);
  cursor: pointer;

  .title {
    margin-top: auto;
    width: max-content;
    position: absolute;
    bottom: 0px;
    left: 12px;
  }
}

@media (max-width: 1800px) {
  .legend-container {
    transform: scale(0.9);
    left: 5px;
    bottom: 15px;
  }
}

@media (max-width: 1500px) {
  .legend-container {
    transform: scale(0.8);
    left: 0;
    bottom: 0;
  }
}

@media (max-width: 1250px) {
  .legend-container {
    transform: scale(0.7);
    left: -7px;
    bottom: -25px;
  }
}

@media (max-width: 1000px) {
  .legend-container {
    transform: scale(0.6);
    left: -10px;
    bottom: -40px;
  }
}

