@import "../../styles/mixins.scss";

.inner-nav {
  height: 5vh;
  width: 100%;
  justify-content: center;
  font-size: 1.2rem;
  background-color: #0B5E96;
  overflow-x: auto;
  
  .nav-wrapper{
    height: inherit;
    width: 100%;

    .nav-links-wrapper {
      display: flex;
      margin: auto;
      
      .nav-link {
        color: #FFFFFF !important;
        padding: 0 3vw !important;
        line-height: 5vh;
        white-space: nowrap;
    
        &.selected-section {
          background-color: #FDC200;
        }
    
        &:focus {
          outline: none;
        }
    
        &:hover {
          background-color: #FDC200;
        }
      }
    }
  }
}