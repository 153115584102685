.chart {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: calc((50vw - 120px)/4);
    
    .value {
        text-align: center;
        font-weight: bold;
        
        &.energy {
            color: #e2ad33;
            background: initial;
        }

        &.wifi {
            color: #7e0cf5;
            background: initial;
        }
    
        &.water {
            color: #007c9f;
            background: initial;
        }

        &.mobsense {
            color: #F35456;
            background: initial;
        }
    
        & > span {
            font-size: 0.75em;
        }
    }

    .bars {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        padding: 0 35px;
        
    
        & > div:not(:last-child) {
            margin-right: 15px;
        }
    }
    .label {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .title {
            color: #888888;
            font-weight: bold;
            margin: 0;
            display: block;
        }
    }
}

.hexbar {
    position: relative;
    width: 25px;
    margin: 7.22px 0;
    transition: height 0.5s ease-out;


    &.water {
        background-color: #007c9f;
        
        &::before {
            border-bottom: 7.22px solid #007c9f;
        }
          
        &::after {
            border-top: 7.22px solid #007c9f;
        }
    }

    &.mobsense {
        background-color: #F35456;
        
        &::before {
            border-bottom: 7.22px solid #F35456;
        }
          
        &::after {
            border-top: 7.22px solid #F35456;
        }
    }
    
    &.energy {
        background-color: #e2ad33;
        height: 100%;
        
        &::before {
            border-bottom: 7.22px solid #e2ad33;
        }
          
        &::after {
            border-top: 7.22px solid #e2ad33;
        }
    }

    &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        border-left: 12.5px solid transparent;
        border-right: 12.5px solid transparent;
    }

    &:before {
        bottom: 100%;
    }
      
    &:after {
        left: 0;
        top: 100%;
        width: 0;
    }
    
}