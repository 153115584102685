.list-filter {
  margin: 0px 20px 0px 0px;
  padding: 0px 15px 0px 15px;
  // margin: 0vh 2vh 0vh 0vh;
  // padding: 0vh 1.5vh 0vh 1.5vh;
  border-radius: 0;
  color: #707070 !important;
  border: 1px solid #707070 !important;
  // border: 0.1vh solid #707070 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 35px;
  // font-size: 1.5vh;
}

.list-filter:focus {
  box-shadow: none;
  background-color: transparent !important;
  color: #707070 !important;
  border: 1px solid #707070 !important;
  // border: 0.1vh solid #707070 !important;
}

.list-filter:hover {
  background-color: #707070 !important;
  color: #FFFFFF !important;
  border: 1px solid #707070 !important;
  // border: 0.1vh solid #707070 !important;
}

.list-filter:active {
  background-color: #707070 !important;
  color: #FFFFFF !important;
  border: 0.1vh solid #707070 !important;
}

.list-filter:after {
  display: none !important;
}

.menu{
  border-radius: 0;
  color: #707070;
}

.item:hover{
  color: inherit !important;
}

.check-button {
  margin-right: 15px;
  // margin-right: 1.5vh;
  // font-size: 1.4vh;
}

.list-filter-label {
  padding-left: 1vh;
  font-size: 14px;
}