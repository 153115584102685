.marker-selector-container {
    width: 225px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 60px;

    button {
      font-family: Roboto, Arial, sans-serif;
      font-size: 18px;
      border-radius: 0;
      border: none;
      font-weight: 400;
      padding: 3px 18px;
      background-color: white;
      color: #565656;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

      &.selected {
        color: black;
        font-weight: 500;
        cursor: default;
      }
    }

    button:hover {
      background-color: #ebebeb;
      color: black;
    }

    button:active {
      color: black !important;
      background-color: white !important;
      border: none !important;
      font-weight: 500;
    }

    button:focus {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    }
  }
  