.alert-header {
  margin: 2.5px 0px;
  color: black;
  font-size: 0.9em;
  min-height: 50px;
  border-width: 3px;
  padding: 5px;
  // font-size: 1.4vh;
  // height: 5vh;
  // border-width: 0.3vh;
  // padding: 0.5vh;
  cursor: pointer;

  &.unread {
    background-color: #dbdbdb;
    color: #303030;
    font-weight: bold;
  }

  &.read {
    color: #303030;
    background-color: #f2f2f2;
  }

  &.archived {
    color: #f2f2f2 !important;
    background-color: #9A9A9A !important;
  }

  &.mitigated {
    color: #f2f2f2 !important;
    border-color: #8A8A8A !important;
    background-color: #9a9a9a !important;
  }

  &.deleted {
    display: none;
  }

  .button {
    align-self: center;
  }

  .text {
    font-size: 0.8rem;
    // font-size: 1.2vh;
    overflow: hidden;
    height: 100%;
    text-overflow: ellipsis;

    &.building {
      display: flex;
      align-items: center;
      // line-height: 40px;
      // line-height: 4vh;
    }
  }

}

.button-group {
  align-self: center;
  text-align: center;
  padding: 0;

  .button {
    width: 25%;
    font-size: 1.6em;
    // font-size: 2vh;

    &.disabled-btn {
      color: #E6E6E6;
      cursor: not-allowed;
    }
  }
}

.alert-description {
  margin: -2.5px 0px 2.5px 0px;
  font-size: 0.9em;
  padding: 5px;
  // font-size: 1.4vh;
  // padding: 0.5vh;
  cursor: pointer;
  background-color: #f2f2f2;
  color: #303030;
  border-top: 1px solid #f2f2f2;
  // border-top: 0.1vh solid #f2f2f2;

  &.unread {
    background-color: #dbdbdb;
  }

  &.read {
    border-color: #dbdbdb;
    background-color: #f2f2f2;
  }

  &.archived {
    color: #f2f2f2 !important;
    border-color: #8A8A8A !important;
    background-color: #9A9A9A !important;

  }

  .content {
    padding: 5px 30px;
    // padding: 0.5vh 3vh;

    .title {
      padding-bottom: 1vh;

      &.unread {
        font-weight: bold;
      }
    }
  }
}