.simulation-disclaimer-container {
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    font-size: 0.7em;
    line-height: 2em;
    padding: 10px;
    width: 275px;
    text-align: center;
    height: 40px;
    position: absolute;
}