.alert {
  margin: 0.5vh;
  color: white;
  font-size: 0.9vh;
  min-width: 35vh;
  max-width: 50vh;
  height: 11vh;
  overflow-y: auto;
  border-width: 0.3vh;
  cursor: pointer;

  .title {
    font-weight: bold;
    margin: 0;
  }

  .content {
    font-size: 0.8vh;
  }

  &.energy {
    color: #f8be22;
    border-color: #f8be22;
  }

  &.wifi {
    background-color: #7e0cf5;
  }

  &.water {
    color: #007c9f;
    border-color: #007c9f;
  }
}
