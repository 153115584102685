.wifi-header {
  border-bottom: 2px solid #7e0cf5;
  color: #7e0cf5;

  & h5 {
    font-weight: bold;
  }

  & p {
    font-size: 14px;
    margin: 5px 0;
  }
}

.wifi-list-item {
  border: none;
  border-radius: 0px !important;
  margin-bottom: 1px;
  border-bottom: 1px solid #7e0cf5;
  align-items: center;

  & .content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-right: 10px;
  }

  & .edit {
    border: none;
    height: 35px;
    width: 35px;
    border-radius: 35px !important;
    transition: 0.2s ease;
    margin-left: 5px;
    background-color: rgb(235, 235, 235) !important;

    &:hover {
      background-color: rgb(231, 231, 231) !important;
      box-shadow: #00000055 0px 1px 5px;
    }

    &:focus {
      outline: none;
    }
  }
}

.wifi-list-header,
.wifi-list-item {
  font-size: 16px;
  display: flex;
  color: #7e0cf5;
  padding: 5px 0px;
  justify-content: space-between;
}

.wifi-unavailable {
  color: gray;
}

.wifi-list-header {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
  margin-right: 50px;

  &.true {
    margin-right: 90px;
  }
}

.buildings-list-header {
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 15px;
  font-size: 16px;
  display: flex;
  color: gray;
  padding: 5px 0px;
  justify-content: space-between;
}

.buildings-list-title {
  color: #ababab;
  font-weight: bold;
  text-transform: uppercase;
}

.buildings-list-item {
  cursor: pointer;
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
  border: none;
  border-radius: 0px !important;
  margin-bottom: 1px;
  border-bottom: 1px solid gray;
  color: gray;
  font-weight: bold;

  &.active {
    background: transparent;
    color: #7e0cf5;
    font-weight: bold;
    border-bottom: 1px solid #7e0cf5;
  }
}

.wifi-tab > * {
  padding: 0 30px;
}

.wifi-tab {
  margin-top: 20px;
  & .button {
    background-color: #7e0cf5;
    border: none;
    margin-top: -40px;
    position: absolute;
    right: 30px;

    &:hover {
      background-color: #5f08bd;
    }

    &:active {
      background-color: #7e0cf5 !important;
    }

    &:focus {
      background-color: #7e0cf5;
      outline: none;
      box-shadow: none !important;
    }
  }
}

.wifi-form {
  align-items: stretch !important;
  padding: 10px;
  & input {
    width: 100% !important;
  }

  & .button {
    background-color: #7e0cf5 !important;
  }
}

.modal-title {
  padding: 10px;
}

.modal-content {
  border: none;
  box-shadow: #00000088 0px 10px 15px;
  border-radius: 0;
}
